import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  width: "100%",
  textAlign: "center"
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyleContainer = {
  textAlign: "center"
}
const listStyles = {
  display: "inline-block",
  marginBottom: 96,
  paddingLeft: 0,
  listStyleType:'none',
}
const listItemStyles = {
  display: "flex",
  textAlign: "left",
  fontWeight: 300,
  fontSize: 24,
  maxWidth: "inherit",
  marginBottom: 30,
  justifyContent: "space-between",
  width: "100%"
}


const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "TypeScript Documentation",
  url: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

const buttonStyle = {
  marginLeft: "8px",
  padding: "0",
  borderRadius: "50%",
  background: "#cd2026",
	color: "#fff",
	boxShadow: "0 6px #ab3c3c",
  border: "none",
  width: "60px",
  height: "60px"
}


const banks = [
  {
    name: "JPMorgan Chase & Co.",
    url: "",
    number: "tel:1-800-270-7760",
    color: "#981b1e",
  },
  {
    name: "Bank of America Corp.",
    url: "https://www.bankofamerica.com/",
    number:"tel:1-800-432-1000",
    hours: "Mon–Fri 8 a.m.-11 p.m. ET  Sat–Sun 8 a.m.-8 p.m. ET",
    color: "#0071bc",
  },
  {
    name: "Wells Fargo & Co.",
    url: "/findmyphone",
    number: "tel:1-800-896-3557",
    color: "#4c2c92",
  },
  {
    name: "Citigroup Inc.",
    url: "/police-report",
    number: "tel:",
    color: "#205493",
  },
  {
    name: "U.S. Bancorp",
    url: "/submit-a-claim",
    number: "tel:1-800-872-2657",
    color: "#2e8540",
  },
  {
    name: "PNC Financial Services Group Inc.",
    url: "/submit-a-claim",
    number: "tel:1-800-762-2265",
    hours: "M-F 7 a.m-10pm ET Sat-Sun 8 a.m.-5 p.m ET",
    color: "#981b1e",
  },
  {
    name: "Truist Bank",
    url: "/submit-a-claim",
    number: "tel:1-800-226-5228",
    color: "#0071bc",
  },
  {
    name: "Goldman Sachs Group Inc.",
    url: "/submit-a-claim",
    number: "tel:1-877-255-5923",
    color: "#4c2c92",
  },
  {
    name: "Capital One Financial Corp.",
    url: "/submit-a-claim",
    number: "tel:1-877-383-4802",
    color: "#205493",
  },
  {
    name: "American Express",
    url: "/submit-a-claim",
    number: "tel:1-877-528-4800",
    color: "#2e8540",
  }
]

const suggestBank = [
  {
    name: "",
    submitBtnText: "Submit"
  }
]

const BankNumbers = () => {
  return (
    <main style={pageStyles}>
      <title>Bank Customer Support Numbers</title>
      <h1 style={headingStyles}>
        Bank Customer Support Numbers
      </h1>
      <div style={listStyleContainer}>
        <ul style={listStyles}>
          {banks.map(bank => (
            <li key={bank.url} style={{ ...listItemStyles, color: bank.color }}>
              <span>
                  {bank.name}
              </span>
              <a
                  style={linkStyle}
                  href={`${bank.number}`}
                >
                <button style={buttonStyle}><FontAwesomeIcon icon={faPhone} size="2x" /></button>
                </a>
            </li>
          ))}
        </ul>
      </div>
      <br />
        <Link to="/">Go home</Link>.
    </main>
  )
}

export default BankNumbers
